import * as React from 'react'
import BGImage from "../images/business-image-1.jpg"
import BGImage1 from "../images/business-image-2.jpg"
import BGImage2 from "../images/business-image-3.jpg"
import BGImage3 from "../images/business-image-4.jpg"

function QuickImage(props){
    return(
        <div className={"p-2 lg:p-4 w-1/2"}>
            <img className={"rounded-xl w-full"} src={props.src} alt={"gallery"}/>
        </div>
    )
}

function SpecialBullets(props){
    return(
        <div className={"flex flex-row p-2"}>
            <div className={"h-10 w-10 flex justify-center align-middle my-auto"}>
                <svg className={"fill-malibu-700 dark:fill-malibu-900"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M448 432c0-14.25 8.547-28.14 21.28-34.55l39.56-16.56l15.64-37.52c4.461-9.037 11.45-15.37 19.43-19.23L544 128c0-17.67-14.33-32-32-32s-32 14.33-32 32l-.0156 112c0 8.836-7.148 16-15.98 16s-16.07-7.164-16.07-16L448 64c0-17.67-14.33-32-32-32s-32 14.33-32 32l-.0635 176c0 8.836-7.106 16-15.94 16S351.9 248.8 351.9 240L352 32c0-17.67-14.33-32-32-32S288 14.33 288 32L287.9 240C287.9 248.8 280.8 256 272 256S255.9 248.8 255.9 240L256 64c0-17.67-14.33-32-32-32S192 46.33 192 64v279.4L132.3 283.7C124.5 275.9 114.2 272 104 272C82.68 272 64 289.2 64 312c0 10.23 3.906 20.47 11.72 28.28l113.1 113.1C226.6 491.2 276.9 512 330.3 512H368c42.72 0 81.91-15.32 112.4-40.73l-9.049-3.773C456.6 460.1 448 446.3 448 432zM349.8 371.6L320 383.1l-12.42 29.78C306.1 415 305.4 416 304 416s-2.969-.9941-3.578-2.219L288 383.1l-29.79-12.42C256.1 370.1 256 369.4 256 367.1c0-1.365 .9922-2.967 2.209-3.577L288 352l12.42-29.79C301 320.1 302.6 320 304 320s2.967 .9902 3.578 2.217L320 352l29.79 12.42C351 365 352 366.6 352 367.1C352 369.4 351 370.1 349.8 371.6zM80 224c2.277 0 4.943-1.656 5.959-3.699l20.7-49.63l49.65-20.71c2.027-1.014 3.682-3.696 3.686-5.958C159.1 141.7 158.3 139.1 156.3 138L106.7 117.4L85.96 67.7C84.94 65.65 82.28 64 80 64C77.72 64 75.05 65.65 74.04 67.7L53.34 117.3L3.695 138C1.668 139.1 .0117 141.7 .0078 143.1c.0039 2.262 1.662 4.953 3.688 5.967l49.57 20.67l20.77 49.67C75.05 222.3 77.72 224 80 224zM639.1 432c-.0039-2.275-1.657-4.952-3.687-5.968l-49.57-20.67l-20.77-49.67C564.9 353.7 562.3 352 560 352c-2.281 0-4.959 1.652-5.975 3.695l-20.7 49.63l-49.64 20.71c-2.027 1.016-3.682 3.683-3.686 5.958c.0039 2.262 1.661 4.954 3.686 5.968l49.57 20.67l20.77 49.67C555.1 510.3 557.7 512 560 512c2.277 0 4.933-1.656 5.949-3.699l20.7-49.63l49.65-20.71C638.3 436.9 639.1 434.3 639.1 432z"/></svg>
            </div>
            <p className={"text-xl pl-4 my-auto font-medium"}>{props.text}</p>
        </div>
    )
}

function AboutBusiness(){
    return(
        <div className={"dark:bg-gray-900 dark:text-white transition duration-500 lg:py-10"}>
            <div className={"flex flex-row flex-wrap-reverse justify-evenly lg:p-16 p-8 max-w-6xl mx-auto"}>
                <div className={"flex flex-row flex-wrap max-w-lg justify-evenly"}>
                    <QuickImage src={BGImage}/>
                    <QuickImage src={BGImage1}/>
                    <QuickImage src={BGImage2}/>
                    <QuickImage src={BGImage3}/>
                    <div className={"p-2 lg:p-4 py-8"}>
                        <SpecialBullets text={"Licensed Business"}/>
                            <SpecialBullets text={"Fully Insured"}/>
                        <SpecialBullets text={"Customers are #1 Priority"}/>
                        <SpecialBullets text={"Next Level Clean"}/>
                        <SpecialBullets text={"Home Cleaning Service with a Smile"}/>
                        <SpecialBullets text={"Call today & you’ll be happy you did"}/>
                    </div>

                </div>
                <div className={"max-w-lg p-2 lg:p-4"}>
                    <h1 className={"text-5xl font-bold text-center pb-8"}><span className={"text-malibu-500"}>North County’s</span> Home Cleaning
                        Experts</h1>
                    <p className={"p-2 lg:p-4 leading-loose"}>Rancho Bernardo Maids was
                        started by Maricela Atempa with
                        the goal of providing the best
                        possible cleaning service for her
                        clients. Her goal was to build a
                        company that could help
                        support her family. As a mother
                        of two beautiful children, she
                        wanted to show them that hard
                        honest work can payoff. She has
                        been working independently for
                        over 10 years, delivering
                        exceptional cleaning services,
                        move outs, and deep cleans for
                        her clients. Her strong work
                        ethic, speed, and attention to
                        detail has helped her make it
                        this far. We want to grow a team
                        and continue to provide the best
                        cleaning service to the North
                        County area.</p>
                    <p className={"p-2 lg:p-4 leading-loose"}>Our customers are very
                        important to us, so we go to
                        great heights to help our
                        customers get their homes in
                        order and next level cleaned. We
                        believe Rancho Bernardo and
                        the surrounding communities
                        are the finest in San Diego. Your
                        need for your home is our
                        concern. Thanks for calling, we
                        will treat you the way you
                        deserve.</p>
                </div>
            </div>
        </div>
    )
}

export default AboutBusiness
