import * as React from 'react'
import Image1 from "../images/1.webp"
import Image2 from "../images/2.webp"
import Image3 from "../images/3.webp"
import Image4 from "../images/4.webp"
import Image5 from "../images/5.webp"
import Image6 from "../images/6.webp"
import Image7 from "../images/7.webp"
import Image8 from "../images/8.webp"
import Image9 from "../images/9.webp"
import Image10 from "../images/10.webp"
import Image11 from "../images/11.webp"
import Image12 from "../images/12.webp"
import Button from "./button";

function SingleImage(props){
    return(
            <img className={"sm:w-1/6 w-1/2"} src={props.src} alt={props.alt}/>
    )
}

function ImageGalleryShowcase(){
    return(
        <>
            <div className={"px-4 py-16 bg-malibu-500"}>
                <div className={"flex justify-evenly items-center flex-wrap max-w-5xl mx-auto"}>
                    <h1 className={"text-center text-6xl text-white font-bold sm:text-6xl max-w-lg"} >Our Work</h1>
                </div>
            </div>
        <div className={"flex flex-wrap"}>
            <SingleImage src={Image1} alt={"Interior wall painting example"}/>
            <SingleImage src={Image2} alt={"Interior wall painting example"}/>
            <SingleImage src={Image3} alt={"Interior wall painting example"}/>
            <SingleImage src={Image4} alt={"Interior wall painting example"}/>
            <SingleImage src={Image5} alt={"Interior wall painting example"}/>
            <SingleImage src={Image6} alt={"Interior wall painting example"}/>
            <SingleImage src={Image7} alt={"Interior wall painting example"}/>
            <SingleImage src={Image8} alt={"Interior wall painting example"}/>
            <SingleImage src={Image9} alt={"Interior wall painting example"}/>
            <SingleImage src={Image10} alt={"Interior wall painting example"}/>
            <SingleImage src={Image11} alt={"Interior wall painting example"}/>
            <SingleImage src={Image12} alt={"Interior wall painting example"}/>

        </div>
        </>
    )
}

export default ImageGalleryShowcase