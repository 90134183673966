import React from 'react'

const serviceAreas = [
    "Rancho Bernardo",
    "Poway",
    "4S Ranch Area",
  "Escondido",
  "San Marcos",
  "Vista",
  "Mira Mesa",
  "Scripps Ranch",
  "Encinitas",
]

const ServiceAreas = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Service Areas</h2>
        <div className="max-w-4xl mx-auto">
          <p className="text-center text-gray-600 mb-8">
            We're proud to serve the following communities in and around San Diego County:
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {serviceAreas.map((area, index) => (
              <div 
                key={index} 
                className="bg-white rounded-lg shadow-md p-4 text-center transition-all duration-300 hover:shadow-lg hover:bg-malibu-50"
              >
                <span className="text-gray-800 font-medium">{area}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceAreas