import * as React from 'react'

function AdditionalService(props){
    return(
        <div className={"max-w-xs rounded m-5 min-h-full "}>
            <div className={"m-2 border-2 border-malibu-500 border-dashed rounded min-h-full"}>
            <h2 className={"text-center pt-5 font-bold"}>{props.title}</h2>
            <p className={"p-5 leading-loose"}>{props.children}</p>
            </div>
        </div>
    )
}

function AdditionalServices(){
    return(
        <div className={"border-y-2 border-malibu-500 py-10 lg:py-20 lg:m-5 dark:bg-gray-900 dark:text-white"}>
            <h1 className={"text-3xl text-center text-malibu-500 pt-5 pb-10"}>Additional Services</h1>
            <div className={"flex flex-row flex-wrap justify-center"}>
                <AdditionalService title={"Clean-up After Parties"}>
                    Bathrooms, kitchen vacuum and
                    mop floors. trash pickup. Clean
                    tables, chairs washing the floors outside, etc.
                </AdditionalService>
                <AdditionalService title={"Bed Making"}>Full bedmaking &
                    turndown service, expect
                    nothing less than perfection
                    when you get into bed</AdditionalService>
                <AdditionalService title={"Oven Cleaning"}>Get your oven
                    back to it’s cleanest and best
                    shape with a full cleaning</AdditionalService>

            </div>
            <p className={"p-2 text-gray-500 text-center"}>Grout cleaning additional fee -
                Window cleaning additional fee</p>

        </div>
    )
}

export default AdditionalServices