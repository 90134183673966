import * as React from 'react'
import SimpleCard from "./simple-card";
import AwardVideo from "../videos/award.mp4"
import LikeVideo from "../videos/like.mp4"
import PhoneVideo from "../videos/phone.mp4"
import SaveMoneyVideo from "../videos/save-money.mp4"
import AwardVideoThumb from "../videos/award-thumb.svg"
import LikeVideoThumb from "../videos/like-thumb.svg"
import PhoneVideoThumb from "../videos/phone-thumb.svg"
import SaveMoneyVideoThumb from "../videos/save-money-thumb.svg"

function CustomerService(props) {
    return (
        <div className={"dark:bg-gray-900 dark:text-white transition duration-500"}>
            <div className={"p-4 max-w-5xl mx-auto pt-10 sm:pt-20"}>
                <div>
                    <h1 className={"text-center text-5xl font-bold pb-12"}>Customer service is our <span
                        className={"text-green-500"}>#1 priority</span></h1>
                    <p className={"px-2 sm:w-3/4 max-w-2xl text-center mx-auto leading-loose"}>We know home renovation
                        work is not the easiest cost to swallow, and it can be difficult finding
                        someone you can trust. If you found us online and are reading our site, we want you to know you
                        found the right company</p>

                </div>
                <div className={"flex flex-row flex-wrap justify-evenly sm:mt-16 mt-8 sm:gap-10"}>
                    <SimpleCard mainText={"Returns Every Call"}
                                bodyText={"Never get left hanging, if you call or leave a message we will call you back when we get down from our ladder!"}
                                src={PhoneVideo} alt={"phone icon"} srcImage={PhoneVideoThumb}/>
                    <SimpleCard mainText={"Great Communication"}
                                bodyText={"We explain everything we have to do in a clear and easy to understand way so you know exactly what you're getting."}
                                src={LikeVideo} alt={"thumbs up icon"} srcImage={LikeVideoThumb}/>
                    <SimpleCard mainText={"High Quality Products"}
                                bodyText={"We use only the best quality paints, cleaners, and equipment in your home. We don't cut corners on cheap materials."}
                                src={AwardVideo} alt={"award icon"} srcImage={AwardVideoThumb}/>
                    <SimpleCard mainText={"Affordable Prices"}
                                bodyText={"Being a small, family owned business we can charge less because we have less overhead and expenses, saving you money!"}
                                src={SaveMoneyVideo} alt={"save money icon"} srcImage={SaveMoneyVideoThumb}/>
                </div>
            </div>
        </div>
    )
}

export default CustomerService